<template>
<div class="div">
  <div id="tiktokAD">
    <img src="https://oss.hshwhkj.com/images/Tiktok/fistDouyin/1.11落地页-1-1.jpg" alt="">
    <img src="https://oss.hshwhkj.com/images/Tiktok/fistDouyin/1.11落地页-2---.jpg" alt="">
    <img src="https://oss.hshwhkj.com/images/Tiktok/fistDouyin/1.11落地页-3.jpg" alt="">
    <img src="https://oss.hshwhkj.com/images/Tiktok/fistDouyin/1.11落地页-4.jpg" alt="" class="bottom">
   <div class="bottomText" v-if="tg_id==2">苏州安捷成文化科技有限公司 </div>
       <div class="bottomText" v-else>上海河山汇文化科技有限公司 </div>
    <img src="https://oss.hshwhkj.com/images/360/1.gif" alt="" class="btn" @click="throttleSubmit">
    <img src="https://oss.hshwhkj.com/images/3.gif    " alt="" class="wx"   @click="throttleSubmit">
  </div>
        <el-dialog :visible.sync="dialogFormVisible" width="82%" :lock-scroll="true" :close-on-click-modal='false'>
            <div v-if="flages">
                <div style="" class="textTitle">
                    1.请问您对《易经》的了解程度如何? <span style="color: red">*</span>
                </div>
                <div style="margin-top: 20px">
                    <div
                        v-for="(text, index) in texts"
                        :key="index"
                        @click="handleClick(index)"
                        :class="{active: index === selected}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="textTitle">2.请问您希望学习《易经》中的哪些知识? <span style="color: red">*</span></div>
                <div style="margin-top: 20px">
                    <div  
                        v-for="(text, index) in textsA"
                        :key="index"
                        @click="throttleSubmitA(index)"
                        :class="{active: index === selectedA}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
        </el-dialog>

       <!-- 确认弹框 -->
        <el-dialog
            :visible.sync="suessesDir"
            width="82%"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            :destroy-on-close="true"
        >
            <!-- <div v-if="dirFlag">
              <div style="margin-top: -40px;margin-bottom: 20px;" class="phonesCss">输入手机号，领取福利</div>
                <el-form  >
                    <el-form-item >
                        <el-input placeholder="信息已加密，请放心填写" v-model="phones" ></el-input>
                    </el-form-item>
                    <el-button style="width: 100%" @click="phoneFlag" round class="submitbtns" 
                        :class="{activeSubmit: phones.length === 11 }"
                    
                    >提交</el-button>
                </el-form>
            </div> -->

            <div v-if="!dirFlag" class="dirsA">
                <div class="imgsA">
                    <img src="https://hshcrm.oss-cn-hangzhou.aliyuncs.com/images/succeed.png" alt="" />
                </div>
                <!-- <div class="audio">
                    <audio controls autoplay ref="myAudio" @ended="audioEnded" @loadedmetadata="getAudioDuration">
                        <source
                            src="https://hshcrm.oss-cn-hangzhou.aliyuncs.com/images/Tiktok/%E5%9B%B0%E5%A2%83.mp3"
                            type="audio/mp3"
                        />
                        Your browser does not support the audio tag.
                    </audio>
                </div> -->
                <div class="imgsB" @click="throttleSubmitB">
              
                        <el-button class="btnns" style="background-color: #c83732; color: #fff"
                            >添加老师,免费领取直播课></el-button
                        >
                        <div style="width: 100% " class="tips">注:70岁以上请勿添加</div>
         
                    <!-- <div v-else>
                        <el-button class="btnns">
                            <div>
                                <span style="color: #c83732"> {{ this.audioTime }}</span
                                >秒后,领取福利
                            </div>
                        <div style="width: 100%;margin: 10px 0;" class="tips">注:70岁以上请勿添加</div>

                        </el-button>
                    </div> -->
                </div>
            </div>
        </el-dialog>
</div>

</template>
<script>
//引入
import {getWxCsad} from "../../api/360AD.js";


export default {
  name: 'tiktokAD',
  components: {
  },
  data() {
   
    return {
          audioTime: 0,
            audioSrc: "",
            DirFlag: false,
            audioFlag: false,
           phones:'',
          dirFlag:false,
        flages: true,
            dialogFormVisible: false,
            suessesDir: false,
            texts: ["略有一些了解", "有一些自己见解", "不是很了解"],
            textsA: ["面相识人学", "旺运姓名学", "家居风水学", "八字命理学"],
            selected: null,
            selectedA: null,
      tg_id:'',

      ruleForm: {
          
                land_link: "", //落地页链接
            },
    };
  },
  created(){
    this.ruleForm.land_link =window.location.href
   this.tg_id=this.$route.query.tg_id

   
  },
  methods: {
  
  getAudioDuration() {
            const audioElement = this.$refs.myAudio;
            console.log(audioElement.duration);
            this.audioTime = Math.trunc(audioElement.duration);
            let countdownTimer = setInterval(() => {
                // 检查时间是否已经减到0
                if (this.audioTime > 0) {
                    // 时间减一
                    this.audioTime--;
                } else {
                    // 当时间到达0时，清除定时器
                    clearInterval(countdownTimer);
                    // 可以在此处添加计时结束后的处理逻辑
                    console.log("Countdown finished!");
                }
            }, 1000);
        },
        audioEnded() {
            console.log("音频播放完毕");
            // 在这里添加您希望在音频播放结束时执行的操作
            this.audioFlag = true;
        },
        phoneFlag() {
            const phoneRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
            if (this.phones.length >= 11 && phoneRegex.test(this.phones)) {
                this.dirFlag = false;
            } else {
                this.$message({type: "error", message: "请输入正确的手机号"});
            }
        },
  handleClick(index) {
            this.selected = index;
            this.flages = false;
        },
        // handleClickA(index) {
        //     this.selectedA = index;
        //     this.wxClick();
        // },
        throttleSubmit() {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 2500) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间
            // this.openDir(); // 调用实际的支付方法
            //     this.$router.push(
            //   {
            //     path: "/wenda",
            //     query: {
            //       tg_id: this.tg_id,
            // land_link: this.ruleForm.land_link,
            //     }
            //   }
            // )
         this.wxClick()
            console.log(1);
        },
        throttleSubmitA(index) {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间

            this.selectedA = index;
            this.dialogFormVisible = false;
            this.suessesDir = true;
            this.flages = true;
       
            // this.wxClick(); // 调用实际的支付方法
        },
                throttleSubmitB() {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }
      
            this.lastClickTime = currentTime; // 更新上次点击时间
            this.wxClick(); // 调用实际的支付方法
        },
        // 打开弹框
        openDir() {
            this.dialogFormVisible = true;
        },
        closeDir() {
            this.dialogFormVisible = false;
            this.flages = true;
            this.selected = null;
            this.selectedA = null;
        },
        async wxClick() {
            // console.log(this.selected);
            // console.log(this.selectedA);
            // if (this.selected === null || this.selectedA === null) {
            //     this.$message({type: "error", message: "请选择选项"});
            // } else {
                const {data} = await getWxCsad(this.ruleForm);
                // console.log(data);
                window.location.href = data.data;
                // this.dialogFormVisible = false;
            // }
        },
  }
};
</script>
<style lang="less">
#tiktokAD img {
  width: 100%;
  display: block;
overflow: hidden;

}

#tiktokAD{
position: relative;
overflow: hidden;
             .bottomText{
          width: 100%;
          height: 20px;
          line-height: 20px;
          text-align: center;
      font-family: SimSun, "宋体", STSong, "华文宋体", serif;
         color:  rgba(115, 114, 110, 1);
font-size: 24px;
margin-top: 20px;
margin-bottom: 80px;

}
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
     .el-dialog{
        overflow: hidden;
    }
    .imgsA {
        width: 120px;
        margin: 0 auto;
        margin-top: -50px;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }
    .imgsB {
        width: 250px;
        height: 58px;
        img {
            width: 100%;
            height: 58px;
            object-fit: contain;
        }
        .tips{
            height: 30px;
            font-weight: 700;
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
    .dirClass{
      border-radius: 12px;
    }
          .btnns {
        background-color: #e8e8e8;
        border: none;
        color: #adadad;
        width: 250px;
        height: 58px;
        border-radius: 50px;
         animation-name: btnns;
    animation-duration: 1s;  
    animation-iteration-count: infinite; 
    animation-direction: alternate;  
    font-size: 18px;
    }
        .audio {
        audio {
            width: 250px;

            height: 45px;
            margin: 10px auto;
        }
    }

};
.btn{
  position: fixed;
  bottom: 0;
}

.wx{
  position: fixed;
top: 50%;
right: -40%;

  height: 90px;
  width: 90px;
  object-fit: contain;

}
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
       .el-dialog{
        overflow: hidden;
    }
    .imgsA {
        width: 120px;
        margin: 0 auto;
 margin-top: -50px;
        margin-bottom: 10px;
        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }
    .imgsB {
        width: 250px;
        height: 58px;
        img {
            width: 100%;
            height: 58px;
            object-fit: contain;
        }
        .tips{
            height: 30px;
            font-weight: 700;
            text-align: center;
            font-size: 16px;
                       margin-bottom: 10px;

        }
    }
    .dirClass{
      border-radius: 12px;
    }
          .btnns {
        background-color: #e8e8e8;
        border: none;
        color: #adadad;
        width: 250px;
        height: 58px;
        border-radius: 50px;
         animation-name: btnns;
    animation-duration: 1s;  
    animation-iteration-count: infinite; 
    animation-direction: alternate;  
    font-size: 18px;
    }
        .audio {
        audio {
            width: 250px;

            height: 45px;
            margin: 10px auto;
        }
    }
.phonesCss{
  font-weight: 700;
  font-size: 18px;
  color: #C83732;
}
.submitbtns{
  color: #adadad;
  background-color: #e8e8e8;
}
.activeSubmit{
   color: #fff;
  background-color: #c83732;
}
@media screen and (min-width: 750px) {
  .div{
     display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  #tiktokAD {
    width: 750px;
overflow: hidden;
                .bottomText{
          width: 100%;
          height: 20px;
          line-height: 20px;
          text-align: center;
      font-family: SimSun, "宋体", STSong, "华文宋体", serif;
         color:  rgba(115, 114, 110, 1);
font-size: 24px;
margin-bottom: 200px;
margin-top: 20px;

}
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
     .el-dialog{
        overflow: hidden;
    }
    .imgsA {
        width: 120px;
        margin: 0 auto;
margin-top: -50px;
margin-bottom: 10px;
        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }
    .imgsB {
        width: 250px;
        height: 58px;
        img {
            width: 100%;
            height: 58px;
            object-fit: contain;
        }
        .tips{
            height: 30px;
            font-weight: 700;
            text-align: center;
            font-size: 16px;
                       margin-bottom: 10px;

        }
        .btnns{
            width: 15px;
        }
    }
    .dirClass{
      border-radius: 12px;
    }
   

  }
  #tiktokAD{
position: relative;
overflow: hidden;

#tiktokAD img {
    width: 750px;

  display: block;
}
.btn{
    width: 750px;
  position: fixed;
  bottom: 0;
}
};

.wx{

object-fit: contain;
  position: fixed;
top: 50%;
right: -20%;

  height: 60px ;
  width: 60px;
  object-fit: contain;

}
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
    .imgsA{
width: 80px;
height: 80px;
margin: 0 auto;
img{
  width: 100%;
  object-fit: contain;
}
    }
    .imgsB{
      margin-top: 20px;
      width: 275px;
      height: 60px;
      img{
        width: 100%;
      height: 100%;
  object-fit: contain;

      }
    }
    .dirClass{
      border-radius: 12px;
    }
 .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin: 0 auto;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
    .imgsA{
width: 80px;
height: 80px;
margin: 0 auto;
img{
  width: 100%;
  object-fit: contain;
}
    }
    .imgsB{
        margin: 0 auto;

      margin-top: 20px;
      width: 275px;
      height: 60px;
      img{
        width: 100%;
      height: 100%;
  object-fit: contain;

      }
      .btnns{
        width: 252px !important;
        height: 52px;
        font-size: 16px;
      }
      .tips{
        font-size: 15px;
      }
    }
    .dirClass{
      border-radius: 12px;
    }
    .phonesCss{
  font-weight: 700;
  font-size: 18px;
  color: #C83732;
}
.submitbtns{
  color: #adadad;
  background-color: #e8e8e8;
}
.activeSubmit{
   color: #fff;
  background-color: #c83732;
}
}

@keyframes btnns {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

</style>